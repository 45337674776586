.App{
  text-align: center;
}
body {
  height: 100%;
  background: #F0F0F0;
}
html {
  height: 100%;
}
.css-b7k0tb-MuiAutocomplete-listbox .MuiAutocomplete-option {
  padding-left: 51px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.react-flow__attribution {
  background: inherit !important;
}
